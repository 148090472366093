class CheckboxFilter {
    initialize(naja)
    {
        // Immediately initialize if DOM is already loaded
        if (document.readyState === 'complete' || document.readyState === 'interactive') {
            this.init();
        } else {
            document.addEventListener('DOMContentLoaded', this.init.bind(this));
        }

        // Add listeners for Naja events
        naja.addEventListener('interaction', this.init.bind(this));
        naja.addEventListener('complete', this.init.bind(this));
    }

    init(event)
    {
        const filters = document.querySelectorAll('.checkbox-filter');
        filters.forEach(filter => {
            const radios = filter.querySelectorAll('.checkbox-filter-radio');
            radios.forEach(radio => {
                radio.addEventListener('change', this.onRadioChange.bind(this));
            });
        });
    }

    onRadioChange(event)
    {
        const radio = event.target;
        const filter = radio.closest('.checkbox-filter');
        const checkboxes = filter.querySelectorAll('.checkbox-filter-checkbox');
        checkboxes.forEach(checkbox => {
            checkbox.checked = false;
        });
        checkboxes.forEach(checkbox => {
            const data = checkbox.getAttribute('data-checkbox-filter-data');
            const select = radio.getAttribute('data-checkbox-filter-select').split(',');
            if (select.includes(data)) {
                checkbox.checked = true;
            }
        });

    }
}

export default CheckboxFilter;
