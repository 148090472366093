class WysiwygExtension {
    initialize(naja)
    {
        // Immediately initialize if DOM is already loaded
        if (document.readyState === 'complete' || document.readyState === 'interactive') {
            this.init();
        } else {
            document.addEventListener('DOMContentLoaded', this.init.bind(this));
        }

        // Add listeners for Naja events
        naja.addEventListener('interaction', this.init.bind(this));
        naja.addEventListener('complete', this.init.bind(this));
    }

    init(event)
    {
        $('.wysiwyg').each(function(){
            let height = Math.max(200, $(this).height() - 13);
            if ($(this).height() <= 0 && $(this).hasClass('h-100')) {
                let modal = $(this).closest('.modal');
                if (modal.length > 0) {
                    height = Math.max(200, modal.height() - 411);
                }
            }

            const isReadOnly = $(this).prop('readonly');
            $(this).summernote({
                height: height,
                toolbar: [],
                callbacks: {
                    onInit: function() {
                        $(this).next('.note-editor').find('button').attr('data-loading-indicator', 'false');
                        if (isReadOnly) {
                            $(this).summernote('disable'); // Make summernote readonly
                            $(this).next('.note-editor').find('.note-editable').css('background-color', 'inherit'); // Retain original background color
                        }
                    }
                }
            });

        })
    }
}

export default WysiwygExtension;
