import naja from "naja";

// import { Tooltip } from 'bootstrap';

class BaseGridExtension {
    initialize(naja) {
        window.addEventListener('DOMContentLoaded', () => this.init());
        naja.addEventListener('complete', () => this.init());
        naja.uiHandler.addEventListener('interaction', () => this.init());
    }

    init() {
        this.selects = $('input[type=checkbox].grid-line-select');
        this.buttons = $('.grid-line-select-button');
        this.updateButtons();
        $(this.selects).each((index, element) => {
            $(element).closest('tr').on('click', (e) => {
                if (!$(e.target).is('input[type=checkbox]')) {
                    let input = $(e.target).closest('tr').find('input[type=checkbox].grid-line-select');
                    input.prop('checked', !input.prop('checked'));
                    this.updateButtons();
                }
            });

        });

        $(document).on('change', 'input[type=checkbox].grid-line-select', () => this.updateButtons())
            .on('change', 'th .select-all-checkbox-wrapper input[type=checkbox]', (e) => {
                this.selects.prop('checked', $(e.target).prop('checked'));
                this.updateButtons()
            })

        this.initColumnResize();
        this.initLongTextTooltip();
    }

    updateButtons() {
        let selectedValues = [];
        this.selects.filter(':checked').each(function (index, element) {
            selectedValues.push($(element).val());
        });
        let id = selectedValues.join('_');

        this.buttons.each((index, button) => {
            const $button = $(button);
            let url = $button.attr('data-url');

            if (id && url) {
                url = url.replace(/id=\d+/, 'id=' + id);
                $button.attr('href', url);
            }

            $button.toggleClass('disabled', selectedValues.length <= 0);
        });
    }

    initColumnResize() {
        const table = document.querySelector('.table');
        if (!table || table.classList.contains('resize-initialized')) return;

        table.classList.add('resize-initialized');

        this.applyDefaultColumnWidths(table);

        const headers = table.querySelectorAll('th');
        headers.forEach(header => {
            const resizer = document.createElement('div');
            resizer.classList.add('resizer');
            header.appendChild(resizer);

            resizer.addEventListener('mousedown', (event) => this.toggleResize(header, event));
        });
    }

    toggleResize(header, event) {
        if (this.isResizing) {
            this.stopResize();
        } else {
            this.startResize(header, event);
        }
    }

    startResize(header, event) {
        this.isResizing = true;
        this.startX = event.clientX;
        this.startWidth = header.offsetWidth;

        this.resizeColumn = (event) => this.resizeColumnHandler(header, event);
        this.stopResize = (event) => this.stopResizeHandler(header, event);

        document.addEventListener('mousemove', this.resizeColumn);
        document.addEventListener('mouseup', this.stopResize);
    }

    resizeColumnHandler(header, event) {
        if (!this.isResizing) return;

        if (event instanceof MouseEvent) {
            const newWidth = this.startWidth + (event.clientX - this.startX);
            header.style.width = `${newWidth}px`;

            event.preventDefault();
        }
    }

    stopResizeHandler(header, event) {
        this.isResizing = false;
        document.removeEventListener('mousemove', this.resizeColumn);
        document.removeEventListener('mouseup', this.stopResize);

        if (event instanceof MouseEvent) {
            const newWidth = this.startWidth + (event.clientX - this.startX);

            const columnClass = Array.from(header.classList).find(cls => cls.startsWith('col-')).replace('col-', '');
            naja.makeRequest('GET', $('.table').closest('.datagrid-wrapper').data('width-link'), {
                column: columnClass,
                width: newWidth
            }, {
                history: false
            });
        }
    }

    applyDefaultColumnWidths(table) {
        const wrapper = document.querySelector('.datagrid-wrapper');
        if (!wrapper || !wrapper.getAttribute('data-width-default')) return;

        const defaultWidths = JSON.parse(wrapper.getAttribute('data-width-default'));

        const headers = table.querySelectorAll('th');
        headers.forEach(header => {
            const columnClass = Array.from(header.classList).find(cls => cls.startsWith('col-')).replace('col-', '');
            if (defaultWidths[columnClass]) {
                const wrapper = document.querySelector('.datagrid-wrapper');
                if (!wrapper) return;

                header.style.width = `${defaultWidths[columnClass]}px`;
            }
        });
    }


    initLongTextTooltip() {
        document.querySelectorAll('.table tbody td:not(.col-select)').forEach(cell => {
            if (cell.querySelector('.btn')) return;

            const fullText = cell.textContent.trim();
            if (fullText.length > 35) {
                cell.setAttribute('data-bs-toggle', 'tooltip');
                cell.setAttribute('data-bs-placement', 'top');
                cell.setAttribute('title', fullText.replace(/\n/g, '<br>'));
            }
        });
    }
}

export default BaseGridExtension;
