/* global naja, $, intervalFunction */
class VisibilityDependentInputsExtension {
    selectors = {
        initializer: '[data-visibility-dependent]',
        input: 'data-visibility-dependent',
        target: 'data-visibility-target',
        reverse: 'data-visibility-reverse',
    }
    naja = null;
    logging = false;

    initialize(naja) {
        this.naja = naja;

        window.addEventListener('DOMContentLoaded', this.init.bind(this));
        naja.addEventListener('complete', this.init.bind(this));
        naja.uiHandler.addEventListener('interaction', this.init.bind(this));
    }

    init() {
        $(this.selectors.initializer).each((index, input) => this.initInput(input));
    }

    initInput(input) {
        const targetInputName = $(input).attr(this.selectors.input);
        const targetValue = $(input).attr(this.selectors.target);
        let isReversed = $(input).attr(this.selectors.reverse);
        if (typeof isReversed === 'undefined') {
            isReversed = false;
        }

        const targetInput = $(input).closest('form').find('*[name="' + targetInputName + '"]');
        const val = targetInput.val();
        this.changeInputVisibility($(input), val == targetValue, isReversed);

        const ident ='.visibility-dependence-' + $(input).attr('name');
        const self = this;
        targetInput.off(ident);
        targetInput.on('change' + ident, function(e) {
            const val = $(e.target).val();
            self.changeInputVisibility($(input), val == targetValue, isReversed);
        });
    }

    changeInputVisibility(input, isSame, isReversed) {
        if (isSame) {
            if (isReversed) {
                input.closest('div').addClass('d-none');
            } else {
                input.closest('div').removeClass('d-none');
            }
        } else {
            if (isReversed) {
                input.closest('div').removeClass('d-none');
            } else {
                input.closest('div').addClass('d-none');
            }
        }
    }
}

export default VisibilityDependentInputsExtension;
