import naja from "naja";
import Sortable from 'sortablejs';

document.addEventListener('DOMContentLoaded', () => {

    init();
    naja.addEventListener('complete', () => {
        init();
    });

    function init() {
        const columnOrder = new ColumnOrder();
        columnOrder.init();

        // Add click event to toggle selection class
        document.addEventListener('click', (e) => {
            if (e.target.classList.contains('draggable-item')) {
                e.target.classList.toggle('selected');
            }
        });

        const saveOrderButton = document.getElementById('saveOrder');
        if (saveOrderButton) {
            saveOrderButton.addEventListener('click', (e) => {
                const columns = Array.from(document.querySelectorAll('#draggable-columns .draggable-item'))
                    .map(item => item.dataset.column);
                const saveOrderUrl = saveOrderButton.getAttribute('data-save-order-url');
                // Use naja.makeRequest to send the order
                naja.makeRequest('POST', saveOrderUrl, {
                    columns: columns
                });
            });
        }
    }

});

naja.addEventListener('complete', () => {
    const columnOrder = new ColumnOrder();
    columnOrder.init();
});

class ColumnOrder {
    init() {
        this.initSortableRows();
        this.initTransferButtons();
        this.initBulkTransferButtons();
    }

    initSortableRows() {
        this.initSortable(document.querySelector("#draggable-columns"));
    }

    initSortable(element) {
        if (element) {
            Sortable.create(element, {
                animation: 150,
                onEnd: (evt) => {
                    this.handleSortEnd(evt);
                },
            });
        }
    }

    handleSortEnd(evt) {
        const order = Array.from(evt.to.children).map(child => child.dataset.column);
        console.log('New order:', order);
    }

    initTransferButtons() {
        const moveToActiveElem = document.getElementById('moveToActive');
        if (moveToActiveElem) {
            moveToActiveElem.addEventListener('click', () => {
                this.moveSelectedItems('#available-columns', '#draggable-columns');
            });
        }

        const moveToAvailableElem = document.getElementById('moveToAvailable');
        if (moveToAvailableElem) {
            moveToAvailableElem.addEventListener('click', () => {
                this.moveSelectedItems('#draggable-columns', '#available-columns');
            });
        }
    }

    initBulkTransferButtons() {
        const removeAllButton = document.getElementById('removeAll');
        if (removeAllButton) {
            removeAllButton.addEventListener('click', () => {
                this.moveAllItems('#draggable-columns', '#available-columns');
            });
        }


        const addAllButton = document.getElementById('addAll');
        if (addAllButton) {
            addAllButton.addEventListener('click', () => {
                this.moveAllItems('#available-columns', '#draggable-columns');
            });
        }

    }

    moveSelectedItems(fromSelector, toSelector) {
        const from = document.querySelector(fromSelector);
        const to = document.querySelector(toSelector);
        const selectedItems = from.querySelectorAll('.selected');

        selectedItems.forEach(item => {
            item.classList.remove('selected');
            to.appendChild(item);
        });
    }

    moveAllItems(fromSelector, toSelector) {
        const from = document.querySelector(fromSelector);
        const to = document.querySelector(toSelector);
        const allItems = from.querySelectorAll('.draggable-item');

        allItems.forEach(item => {
            item.classList.remove('selected');
            to.appendChild(item);
        });
    }
}

export default ColumnOrder;
