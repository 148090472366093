import naja from "naja";

document.addEventListener('DOMContentLoaded', () => {
    const initAcl = $('.initAclResource');
    if (!initAcl.length) {
        return;
    }

    const acl = new AclRole();
    acl.init();

    naja.addEventListener('complete', () => {
        acl.init();
    });
});

class AclRole {
    init() {
        this.initCheckboxes();
    }

    initCheckboxes() {
        document.querySelectorAll('.checkAll').forEach(button => {
            button.addEventListener('click', (event) => {
                event.preventDefault();
                const treeItem = button.closest('.datagrid2-tree-item');
                treeItem.querySelectorAll('input[type="checkbox"]').forEach(checkbox => {
                    checkbox.checked = true;
                });
            });
        });

        document.querySelectorAll('.uncheckAll').forEach(button => {
            button.addEventListener('click', (event) => {
                event.preventDefault();
                const treeItem = button.closest('.datagrid2-tree-item');
                treeItem.querySelectorAll('input[type="checkbox"]').forEach(checkbox => {
                    checkbox.checked = false;
                });
            });
        });
    }
}
